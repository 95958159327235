import miLogo from './imagenes/miLogo.png';
import './App.css';
import ListaDeTareas from './componentes/ListaDeTareas';


function App() {
  return (
    <div className='app-tareas'>
      <div className='logo-contenedor'>
        <img src={miLogo} className='app-logo' />
      </div>

      <div className='lista-principal'>
        <h1>Mis Tareas</h1>
        <ListaDeTareas />
       
      </div>

    </div>
  
  );
}

export default App;
